import React, { useContext, useLayoutEffect, useRef } from 'react'
import { GameContext, LayoutContext } from '../store'
import moment from 'moment'
import Scrollbar from 'react-scrollbar'
import './turn-table.scss'

const renderMessage = (message, gameLanguage) => {
    let returnMessage = message.value || ''
    const keys = Object.keys(gameLanguage.others)
    keys.forEach((key) => {
        returnMessage = returnMessage.replace(key, gameLanguage.others[key])
    })
    return returnMessage
}

const notationLanguage = (notation, mappings) => {
    let _notation = String(notation)
    Object.keys(mappings).forEach((alphabet) => {
        _notation = _notation.replace(alphabet, mappings[alphabet])
    })
    return _notation
}

export const TurnTable = React.memo(() => {
    const { state: gameState } = useContext(GameContext)
    const { state: layoutState } = useContext(LayoutContext)
    const { history } = gameState
    const { languageJson } = layoutState

    const mappings = languageJson.chess.alphabets
    const chessHeight = layoutState.chessDimensions.height
    const containerEl = useRef(null)

    const scrollToBottomMessageContainer = () => {
        setTimeout(() => {
            if (containerEl.current) {
                let { scrollBottom } = containerEl.current.scrollArea
                scrollBottom()
            }
        }, 100)
    }

    useLayoutEffect(() => {
        if (chessHeight && containerEl.current) {
            containerEl.current.scrollArea.scrollTop()
        }
    }, [chessHeight])

    useLayoutEffect(() => {
        scrollToBottomMessageContainer()
    }, [history.length || 0])

    if (!chessHeight) return (<div></div>)

    return (
        <Scrollbar
            horizontal={ false }
            vertical
            className="turn-table w-100 h-80"
            ref={ containerEl }
            smoothScrolling={ true }
            verticalScrollbarStyle={ {
                background: 'white',
                opacity: 1,
                width: '5px',
                borderBottomRightRadius: '2px',
                borderBottomLeftRadius: '2px',
                borderTopRightRadius: '2px',
                borderTopLeftRadius: '2px',
            } }
            verticalContainerStyle={ { opacity: 1 } }
        >
            <table>
                <tbody>
                {
                    history.length && history.map((el, num) => (
                        <tr key={ num }>
                            <td>
                                { el.date ? moment.unix(el.date).format('HH:mm:ss') : '' }
                            </td>
                            <td>
                                { (el?.labels || []).map(
                                    (item, _index) => (<span key={ `${ num }-${ _index }` }>{ renderMessage(item, languageJson.chess) }</span>)) }
                            </td>
                            <td>
                                { el?.labels.map((item, index) => (<div key={ `${ num }-${ index }` }>
                                    { item.notation && item.notation.color === 'w' ? notationLanguage(item.notation.san, mappings) : '' }
                                </div>)) }
                            </td>
                            <td>
                                { el?.labels.map((item, ___index) => (<div key={ ___index }>
                                    { item.notation && item.notation.color === 'b' ? notationLanguage(item.notation.san, mappings) : '' }
                                </div>)) }
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </Scrollbar>
    )
}, () => true)
