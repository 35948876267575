import clsx from 'clsx'
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import './stats-modal.scss'

export const StatsModal = ({ opened, children, toggle }) => {
  return (
    <div
      className={clsx({
        "stats-modal": true,
        open: !!opened,
      })}
    >
      <div className="content">
        {children}

        <div className="close" onClick={toggle}>
          <AiOutlineClose />
        </div>
      </div>
    </div>
  );
};
