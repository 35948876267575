import clsx from 'clsx'
import React from 'react'

import './button.scss'

export const Button = ({color, children, icon, cursor, ...props}) => {
    return (
        <button { ...props } className={ clsx({'ludo-button': true, prefix: !!icon}) } style={ {background: color, cursor: cursor || 'pointer'} }>
            {
                icon &&
                <span className="icon">
                    <img src={ icon } alt="button icon"/>
                </span>
            }
            { children }
        </button>
    );
};
