import React, { Fragment, useContext } from 'react'
import { LayoutContext } from '../../store'

export const Info = () => {
  const { state: layoutState } = useContext(LayoutContext);

  const { languageJson } = layoutState;

  return (
    <Fragment>
      <p className="title text-center">
        {languageJson.game.CHESSinfoPopoverHeader}
      </p>
      <div>
        <p className="fs-1 t-orange text-center">
          {languageJson.game.CHESSinfoPopoverPart1.split("\n").map((el) => [
            el,
            <br />,
          ])}
        </p>

        <p className="fs-1 t-orange text-center">
          {languageJson.game.CHESSinfoPopoverPart2.split("\n").map((el) => [
            el,
            <br />,
          ])}
        </p>

        <p className="fs-1 t-orange text-center">
          {languageJson.game.CHESSinfoPopoverPart3.split("\n").map((el) => [
            el,
            <br />,
          ])}
        </p>
      </div>
    </Fragment>
  );
};
