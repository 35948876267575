import { useLayoutEffect, useState } from 'react'
import { Orientation } from '../utils/constants'
import { disableScroll } from '../utils/zoom'

export function useWindowDims() {
  // Initialize state with undefined width/height so server and client renders match

  const [windowDims, setWindowDims] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    orientation: null,
  });

  const handleResize = () => {
    setWindowDims((prev) => ({
      ...prev,
      width: window.innerWidth,
      height: window.innerHeight,
    }));
  };

  const hanldeOrientation = () => {
    let _orientation = null;

    if (window.matchMedia("(orientation: portrait)").matches) {
      _orientation = Orientation.PORTRAIT;
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      _orientation = Orientation.LANDSCAPE;
    }

    setWindowDims((prev) => ({
      ...prev,
      orientation: _orientation,
    }));
  };

  const handleChanges = () => {
    handleResize();
    hanldeOrientation();
  };

  useLayoutEffect(() => {
    disableScroll();

    window.addEventListener("resize", handleChanges);
    window.addEventListener("orientationchange", handleChanges);

    handleChanges();

    return () => {
      window.removeEventListener("orientationchange", handleChanges);
      window.addEventListener("orientationchange", handleChanges);
    };
  }, []);
  console.log('windowDims', windowDims)
  return windowDims;
}
