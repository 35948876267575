import React, { useContext } from 'react'
import { GameContext, LayoutContext } from '../store'

import './score-card.scss'

export const ScoreCard = React.memo(() => {
  const { state: gameState } = useContext(GameContext);
  const { state: layoutState } = useContext(LayoutContext);

  const { myWin, myLoss, myDraw, oppWin, oppLoss, oppDraw } = gameState.score;
  const { languageJson, opponentInfo } = layoutState;

  return (
    <div className="score-card">
      <table>
        <tbody>
        <tr>
          <td></td>
          <td className="heading">{"You"}</td>
          <td className="heading">{opponentInfo?.opponentName}</td>
        </tr>
        <tr>
          <td className="heading">{languageJson.game.points}</td>
          <td>{myWin + myDraw}</td>
          <td>{oppWin + oppDraw}</td>
        </tr>
        <tr>
          <td className="heading">{languageJson.game.win}</td>
          <td>{myWin}</td>
          <td>{oppWin}</td>
        </tr>
        <tr>
          <td className="heading">{languageJson.game.loss}</td>
          <td>{myLoss}</td>
          <td>{oppLoss}</td>
        </tr>
        <tr>
          <td className="heading">{languageJson.game.draw}</td>
          <td>{myDraw}</td>
          <td>{oppDraw}</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
});
