import { useRoutes } from 'react-router-dom'
import { App } from './app'

export default function Routes() {
    return (
        <>
            {
                useRoutes([
                    {
                        path: '/',
                        element: <App/>
                    },
                    {
                        path: '/game',
                        element: <App/>
                    },
                    {
                        path: '/*',
                        element: <App/>
                    },
                ])
            }
        </>
    )
}
