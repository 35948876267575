import React, { useContext } from 'react'
import clsx from 'clsx'
import { Button } from './index'

import InfoIcon from '../assets/info.webp'
import BadgeIcon from '../assets/badge.webp'

import './menu.scss'
import { GameContext, LayoutContext } from '../store'

export const Menu = ({ opened, toggleMenu, toggleModal }) => {
    const { state: gameState, actions } = useContext(GameContext)
    const { state: layoutState } = useContext(LayoutContext)

    const { gameOver, gamePrompt } = gameState
    const { languageJson } = layoutState

    const { drawGame, resignGame, resetGame } = actions

    const clickWrapper = (fn) => () => {
        toggleMenu()
        fn && fn()
    }

    return (
        <div
            className={clsx({
                'hamburger-menu': true,
                open: opened,
            })}
        >
            <div className="content">
                {/*<div className="item" onClick={(e) => toggleModal("language", true)}>*/}
                {/*  <Button icon={LanguageIcon} color={"#173562"}>*/}
                {/*    {languageJson.game.CHESSlanguagePopoverHeader}*/}
                {/*  </Button>*/}
                {/*</div>*/}
                <div className="item" onClick={(e) => toggleModal('info', true)}>
                    <Button color={'#173562'}>
                        <div className="d-flex align-items-center justify-content-start w-100">
                            <img
                                src={InfoIcon}
                                alt="button icon"
                                style={{ width: '15px',height:'15px', marginRight: '15px' }}/>
                            {languageJson.game.CHESSinfoPopoverHeader}
                        </div>
                    </Button>
                </div>
                {/*<div className="item" onClick={(e) => toggleModal("faq", true)}>*/}
                {/*  <Button icon={FAQIcon} color={"#173562"}>*/}
                {/*    {languageJson.game.CHESShelpPopoverHeader}*/}
                {/*  </Button>*/}
                {/*</div>*/}
                <div className="item" onClick={(e) => toggleModal('stats', true)}>
                    <Button color={'#173562'}>
                        <div className="d-flex align-items-center justify-content-start w-100">
                            <img src={BadgeIcon} alt="button icon" style={{ width: '12px', height:'15px', marginRight: '15px' }}/>
                            {languageJson.game.CHESSscorePopoverHeader}
                        </div>
                    </Button>
                </div>
            </div>

            <div className="game-over-message-container">
                {
                    gamePrompt.displayStatus &&
                    <div className="game-over-msg">
                        {languageJson.game[gamePrompt.messageKey]}
                    </div>
                }
            </div>

            <div className="actions">
                <div className="item">
                    <Button
                        onClick={clickWrapper(resetGame)}
                        disabled={!gameOver}
                        color={'#3A6D54'}
                    >
                        {languageJson.game.newGame}
                    </Button>
                </div>
                <div className="item">
                    <Button
                        onClick={clickWrapper(resignGame)}
                        disabled={gameOver}
                        color={'#FF6B39'}
                    >
                        {languageJson.game.resignGame}
                    </Button>
                </div>
                <div className="item">
                    <Button
                        onClick={clickWrapper(drawGame)}
                        disabled={gameOver}
                        color={'#FFBA03'}
                    >
                        {languageJson.game.drawGame}
                    </Button>
                </div>
            </div>
        </div>
    )
}
