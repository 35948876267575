import React from 'react'

import PalatarLogo from '../assets/ludo.webp'
import LandscapeWarningAsset from '../assets/landscape-warning.webp'
import './landscape-warning.scss'

export const LandscapeWarning = () => (
    <div className="landscape-warning">
        <div className="content d-flex flex-column h-100 justify-content-center align-items-start">
            <img src={PalatarLogo} alt="palatar-logo"/>
            <span>Please rotate your device to play the game</span>
        </div>
        <div className="display d-flex flex-column h-100 justify-content-end align-items-center">
            <img src={LandscapeWarningAsset} alt="landscape-warning-icon"/>
        </div>
    </div>
)
