export const ScreenState = {
  SMALL: "small", // no chat functionality.
  MEDIUM: "medium", // chat functionlaity, but doesn't consume entire screen.
};

export const Orientation = {
  LANDSCAPE: "LANDSCAPE",
  PORTRAIT: "PORTRAIT",
};

export const Color = Object.freeze({
  b: `black`,
  w: `white`,
});

export const PIECES = Object.freeze({
  p: `pawn`,
  b: `bishop`,
  r: `rook`,
  n: `knight`,
  q: `queen`,
  k: `king`,
});

export const Languages = Object.freeze({
  english: `en`,
  french: `fr`,
  spanish: `esp`,
});
