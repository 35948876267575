import React, { Fragment, useContext } from 'react'
import { ScoreCard, TurnTable } from '../'
import { LayoutContext } from '../../store'

export const Stats = () => {
    const { state: layoutState } = useContext(LayoutContext)
    const { languageJson } = layoutState
    return (
        <Fragment>
            <p className="title text-center">{languageJson.game.Score}</p>
            <ScoreCard/>
            <p className="title text-center">{languageJson.game.Moves}</p>
            <div>
                <TurnTable/>
            </div>
        </Fragment>
    )
}
