import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'

import moment from 'moment'

import './timer.scss'

export const Timer = React.memo(
    React.forwardRef(({ myTurn, paused, value, resetTime }, ref) => {
        const [time, setTime] = useState(0);
        const timeRef = useRef(0);
        const timerInterval = useRef();

        useImperativeHandle(
            ref,
            () => ({
                time,
                setTime: (clock) => {
                    setTime(clock);
                    timeRef.current = clock;
                },
            }),
            [time]
        );

        useEffect(() => {
            if (resetTime) {
                setTime(0)
            }
        }, [resetTime])
        useEffect(() => {
            if (value) return;

            timerInterval.current = setInterval(() => {
                if (!paused && myTurn) {
                    setTime((prev) => prev + 1000);
                    timeRef.current += 1000;
                }
            }, 1000);

            return () => {
                timerInterval.current && clearInterval(timerInterval.current);
            };
        }, [paused, myTurn]);

        const _time = moment.duration(value || time, "milliseconds");

        let hours = _time.get("hours");
        let minutes = _time.get("minutes");
        let seconds = _time.get("seconds");

        if (hours <= 9) {
            hours = "0" + hours;
        }
        if (minutes <= 9) {
            minutes = "0" + minutes;
        }
        if (seconds <= 9) {
            seconds = "0" + seconds;
        }

        return <div className="timer">{`${hours}:${minutes}:${seconds}`}</div>;
    })
);
