import React from 'react'
import { Button, Timer } from '../components'
import WhiteLabel from '../assets/whites.svg'
import BlackLabel from '../assets/blacks.svg'
import './player-info.scss'

const Lablers = {
    black: BlackLabel, white: WhiteLabel,
};
export const PlayerInfo = ({color, name, timePos, labelColor, timerProps, resetTime}) => {
    return (<div className="player-info">
        { timePos === 'top' && <Timer resetTime={ resetTime } { ...timerProps } /> }
        <div className="backdrop">
            <img src={ Lablers[color] } alt="king"/>
        </div>
        <div className={ timePos }>
            <Button color={ labelColor } cursor="default">{ name }</Button>
            { timePos === 'bottom' && <Timer resetTime={ resetTime } { ...timerProps } /> }
        </div>
    </div>);
};
