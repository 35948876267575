import React, { useContext } from 'react'
import { GameContext } from '../store'

import './captured-pieces.scss'

export const CapturedPieces = ({color }) => {
  const { state } = useContext(GameContext);

  const { capturedPieces } = state;

  return (
    <div className="captured-pieces-container">
      {capturedPieces
        .filter((el) => el.color === color)
        .map((el, index) => {
          return (
            <div className="captured-piece" key={index}>
              <img src={`/img/chesspieces/default/${color + el.piece}.webp`}  alt="captured-piece"/>
            </div>
          );
        })}
    </div>
  );
};
