import clsx from 'clsx'
import React from 'react'
import './hamburger.scss'

export const Hamburger = ({ opened, onClick }) => {
    return (
        <div className="hamburger-wrapper">
            <div data-cy="navbar-hamburger" className="hamburger" onClick={onClick}>
                {
                    Array.from(
                        Array(3), (_, i) =>
                            <div
                                key={i}
                                className={clsx({
                                    open: opened,
                                })}
                            />
                    )
                }
            </div>
        </div>
    );
};
