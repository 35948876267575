import React from 'react'
import ReactDOM from 'react-dom/client'
import Routes from './Routes'

import { GameProvider, LayoutProvider, WidgetProvider } from './store'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <LayoutProvider>
            <WidgetProvider>
                <GameProvider>
                    <Routes/>
                </GameProvider>
            </WidgetProvider>
        </LayoutProvider>
    </BrowserRouter>
    // </React.StrictMode>
)
