import React, { useContext } from 'react'
import { FiMic, FiMicOff, FiVideo, FiVideoOff } from 'react-icons/fi'
import VideoWavesIcon from '../images/video-waves.svg'
import VideoWavesMutedIcon from '../images/video-waves-muted.svg'
import { MdCallEnd } from 'react-icons/md'
import { LayoutContext } from '../store'

import './cam.scss'

export const Cam = ({ showControls, children, speakerControlsOnly }) => {

    const { state: layoutState, setState: setLayoutState } = useContext(LayoutContext);

    const { audio, video, opponentVideoPlaying, loadWelcomeVideo } = layoutState;

    return (
        <div className="cam">
            {children}
            {showControls && (
                <div className="cam-controls">
                    <div data-cy="user-cam-mic"
                         className="item default stop-bubble"
                         onClick={() => {
                             setLayoutState((prev) => ({
                                 ...prev,
                                 audio: !audio,
                             }));
                         }}
                    >
                        {audio ? <FiMicOff/> : <FiMic/>}
                    </div>
                    <div data-cy="user-cam-video"
                         className="item default stop-bubble"
                         onClick={() => {
                             setLayoutState((prev) => ({
                                 ...prev,
                                 video: !video,
                             }));
                         }}
                    >
                        {video ? <FiVideo/> : <FiVideoOff/>}
                    </div>
                    <div data-cy="user-cam-callEnd"
                         className="item stop-bubble"
                         onClick={() => {
                             window.location.href = process.env.REACT_APP_REDIRECT_URL;
                         }}
                    >
                        <MdCallEnd/>
                    </div>
                </div>
            )}

            {
                speakerControlsOnly &&
                <div className="cam-controls">
                    <div data-cy="opponent-cam-video"
                         className="item default stop-bubble speaker-icon"
                         onClick={() => {
                             setLayoutState((prev) => ({
                                 ...prev,
                                 opponentVideoPlaying: !loadWelcomeVideo ? true : opponentVideoPlaying ? !opponentVideoPlaying : true,
                                 loadWelcomeVideo: true,
                                 opponentAudio: true
                             }));
                         }}
                    >
                        {opponentVideoPlaying ? <img src={VideoWavesIcon} className="video-waves" alt="video-waves"/> :
                            <img src={VideoWavesMutedIcon} className="video-waves" alt="video-waves"/>}
                    </div>
                </div>
            }
        </div>
    );
};
