import React, { useEffect, useState } from 'react'
import { ScreenState } from '../utils/constants'

export const WidgetContext = React.createContext();

const getDefaultState = () => {
  return {
    muted: true,
    screenState: ScreenState.SMALL,
    showControls: false,
  };
};

export const WidgetProvider = ({ children }) => {
  const [state, setState] = useState(() => {
    return getDefaultState();
  });

  // load default state
  useEffect(() => {
    setState(getDefaultState());
  }, []);

  // change controls visibility on screen size change
  useEffect(() => {
    let _showControls = false;
    if (state.screenState === ScreenState.MEDIUM) {
      _showControls = true;
    }

    setState((prev) => ({ ...prev, showControls: _showControls }));
  }, [state.screenState]);

  return (
    <WidgetContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
