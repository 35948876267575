import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Chessboard } from '../utils/chessboard-1.0.0'
import { GameContext, LayoutContext } from '../store'
import { Promotion } from './promotion'
import '../../node_modules/@chrisoakman/chessboardjs/dist/chessboard-1.0.0.css'
import './chess.scss'

import { default_piece_theme, uscf_board_theme } from '../utils/chessboardjs-themes'

var board = null
window.board = board

export const ChessBoard = React.memo(
    () => {
        const [myfen, setMyfen] = useState('')
        const [initialBoardLoad, setInitialBoardLoad] = useState(false)
        const { state, setState } = useContext(GameContext)
        const { setState: setLayoutState } = useContext(LayoutContext)
        const [_move, _setMove] = useState(null)
        const { game, gameOver, fen, color, check, move, myTurn } = state

        const chessEl = useRef()

        useEffect(() => {
            if (myfen && !initialBoardLoad) {
                setInitialBoardLoad(true)
                board.position(game.fen())
            }
        }, [myfen, initialBoardLoad])

        useEffect(() => {
            board && board.position(game.fen())
        }, [state.fen])

        const onDragStart = useMemo(
            () => (source, piece, position, orientation) => {
                setLayoutState((prev) => ({
                    ...prev,
                    loadWelcomeVideo: true,
                    opponentAudio: true
                }))
                const _color = color === 'w' ? 'b' : 'w'
                if (game.isGameOver() || gameOver) return false
                if (piece.includes(_color)) return false
                if (!myTurn) return false
            },
            [gameOver, color, myTurn]
        )

        const checkPromotion = (source, target, orientation) => {
            var t = game.get(target)
            const orient = orientation === 'w' ? 'white' : 'black'

            var tt = target.split('')[0]
            var ss = source.split('')[0]
            if (
                ss === 'a' &&
                !game.inCheck() &&
                ((tt === 'a' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'b' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            } else if (
                ss === 'b' &&
                !game.inCheck() &&
                ((tt === 'b' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'a' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))) ||
                    (tt === 'c' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            } else if (
                ss === 'c' &&
                !game.inCheck() &&
                ((tt === 'c' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'b' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))) ||
                    (tt === 'd' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            } else if (
                ss === 'd' &&
                !game.inCheck() &&
                ((tt === 'd' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'c' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))) ||
                    (tt === 'e' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            } else if (
                ss === 'e' &&
                !game.inCheck() &&
                ((tt === 'e' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'd' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))) ||
                    (tt === 'f' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            } else if (
                ss === 'f' &&
                !game.inCheck() &&
                ((tt === 'f' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'e' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))) ||
                    (tt === 'g' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            } else if (
                ss === 'g' &&
                !game.inCheck() &&
                ((tt === 'g' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'f' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))) ||
                    (tt === 'h' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            } else if (
                ss === 'h' &&
                !game.inCheck() &&
                ((tt === 'h' && (!t || t.color === 'w') && (!t || t.type === 'p')) ||
                    (tt === 'g' &&
                        (t ||
                            (orient === 'white' && t && t.color === 'b') ||
                            (orient === 'black' && t && t.color === 'w'))))
            ) {
                return true
            }
            return false
        }

        const promotionSelected = (choice) => {
            try {
                let _state = {}

                const move = game.move({
                    from: _move.source,
                    to: _move.target,
                    promotion: choice, // NOTE: always promote to a queen for example simplicity
                })
                // illegal move
                if (move === null) return 'snapback'

                _state.fen = game.fen()
                _state.move = move

                if (!move.san.includes('#')) _state.myTurn = false

                _setMove(null)
                setState((prev) => ({ ...prev, ..._state }))
            } catch (err) {
                return 'snapback'
            }
        }

        const onDrop = (source, target) => {
            let _state = {}

            let isPromotionCase =
                ((color === 'w' && (source.split('')[1] === '7' && target.split('')[1] === '8')) ||
                    (color === 'b' && (source.split('')[1] === '2' && target.split('')[1] === '1'))) &&
                checkPromotion(source, target, color) &&
                game.get(source).type === 'p'

            if (isPromotionCase) {
                return _setMove({
                    source,
                    target,
                })
            }
            try {
                // see if the move is legal
                const move = game.move({
                    from: source,
                    to: target,
                })

                // illegal move
                if (move === null) return 'snapback'

                _state.fen = game.fen()
                _state.move = move

                if (!move.san.includes('#')) _state.myTurn = false

                setState((prev) => ({ ...prev, ..._state }))
            } catch (err) {
                return 'snapback'
            }
        }

        useLayoutEffect(() => {
            setTimeout(() => {
                setLayoutState((prev) => ({
                    ...prev,
                    chessDimensions: {
                        width: chessEl.current.offsetWidth * 0.1,
                        height: chessEl.current.offsetHeight * 0.1,
                    },
                }))
            }, 1000)
        }, [])

        useLayoutEffect(() => {
            setTimeout(() => {
                window.onresize = () => {
                    board.resize()
                }
            }, 2000)
        }, [])

        useLayoutEffect(() => {
            setTimeout(() => {
                board = Chessboard('myBoard', {
                    position: fen,
                    draggable: true,
                    pieceTheme: default_piece_theme,
                    orientation: color === 'w' ? 'white' : 'black',
                    boardTheme: uscf_board_theme,
                    onDragStart,
                    onDrop,
                })

                setMyfen(board.fen())
            }, 300)
        }, [color, onDragStart])

        let checkClass

        if (check && move.color === 'w') {
            checkClass = `bK-check`
        }

        if (check && move.color === 'b') {
            checkClass = `wK-check`
        }

        return (
            <div className="d-flex flex-column chess-board align-items-center justify-content-center">
                {_move && <Promotion color={color} cb={promotionSelected}/>}
                <div
                    ref={chessEl}
                    id="myBoard"
                    className={`chess-board-style-default ${checkClass}`}
                ></div>
            </div>
        )
    },
    () => true
)
