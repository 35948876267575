import React from 'react'
import clsx from 'clsx'
import './promotion.scss'

const PromotionPieces = ["q", "n", "b", "r"];

export const Promotion = ({ color, cb }) => {
  return (
    <div className="promotion-modal">
      <div className="content">
        {PromotionPieces.map((el, index) => (
          <div key={index}
            className={clsx({
              "promotion-item": true,
            })}
            onClick={() => cb && cb(el)}
          >
            <img src={`/img/chesspieces/default/${color + el}.webp`} alt="chess-piece"/>
          </div>
        ))}
      </div>
      <div className="overlay"></div>
    </div>
  );
};
