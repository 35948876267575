import React, { Fragment, useContext, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { GameContext, LayoutContext } from '../store'

import { Button, Cam, CapturedPieces, ChessBoard, GameInfo, PlayerInfo, Timer, TurnTable } from '../components'

import './game.scss'

const videoConstraints = {
    width: 1280, height: 720, facingMode: 'user',
}

export const Game = React.memo(({ portraitView, landscapeView }) => {
    const { state: gameState, actions } = useContext(GameContext)
    const { state: layoutState } = useContext(LayoutContext)
    const videoEl = useRef(null)

    const [resetTime, setResetTime] = useState(false)
    const { drawGame, resignGame, resetGame } = actions

    const { gameOver, drawGameButtonDisabled, myTurn, color } = gameState

    const {
        languageJson, opponentInfo, audio, video, opponentAudio, opponentVideoPlaying, loadWelcomeVideo,
    } = layoutState

    const userTimerProps = {
        myTurn: myTurn, paused: gameOver || !myTurn,
    }
    const botTimerProps = {
        myTurn: !myTurn, paused: gameOver || myTurn,
    }
    const roomTimerProps = {
        myTurn: !gameOver, paused: gameOver,
    }

    const opponentColor = color === 'w' ? 'b' : 'w'

    const nextVideo = () => {
        videoEl.current?.setAttribute('src', opponentInfo.loopFile)
        videoEl.current?.load()
        videoEl.current?.play()
    }

    const handlePlayVideo = () => {
        videoEl.current?.play()
    }

    const handlePauseVideo = () => {
        videoEl.current?.pause()
    }

    setTimeout(() => {
        opponentVideoPlaying ? handlePlayVideo() : handlePauseVideo()
    }, 0)

    return (<div className="game">
        <div className="row h-100">
            { /* for mobile  */}

            {
                portraitView &&
                <Fragment>
                    <div className="col col-12 d-flex flex-column justify-content-between">
                        <div className="col d-flex justify-content-between col-12">
                            <div className="col col-5">
                                <Cam speakerControlsOnly>
                                    {
                                        opponentInfo &&
                                        <video
                                            controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                                            autoPlay={true}
                                            disablePictureInPicture={true}
                                            disableRemotePlayback={true}
                                            playsInline={true}
                                            src={loadWelcomeVideo ? opponentInfo.welcomeFile : opponentInfo.loopFile}
                                            preload="metadata"
                                            ref={videoEl}
                                            onEnded={nextVideo}
                                            muted={!opponentAudio}
                                        />
                                    }
                                </Cam>
                            </div>
                            <div className="col col-5">
                                <Cam showControls={true}>
                                    <Webcam
                                        audio={!audio}
                                        videoConstraints={video ? videoConstraints : false}
                                    />
                                </Cam>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-between col-12 flex-column">
                            <div className="col col-12 my-3">
                                <GameInfo
                                    opponentColor={opponentColor !== 'w' ? 'w' : 'b'}
                                    color={opponentColor === 'w' ? 'w' : 'b'}
                                    name={opponentInfo?.opponentName}
                                    timerProps={botTimerProps}
                                />
                            </div>
                        </div>
                        <div className="flex-fill d-flex justify-content-center align-items-center position-static">
                            <ChessBoard/>
                        </div>
                        <div className="col d-flex justify-content-between col-12 flex-column">
                            <div className="col col-12 my-3">
                                <GameInfo
                                    opponentColor={opponentColor === 'w' ? 'w' : 'b'}
                                    color={opponentColor !== 'w' ? 'w' : 'b'}
                                    name="You"
                                    timerProps={userTimerProps}/>
                            </div>
                        </div>

                    </div>
                </Fragment>
            }

            {/* Desktop and Ipad Landscape */}

            {
                landscapeView &&
                <Fragment>
                    <div
                        className="col col-sm-2 d-flex flex-column justify-content-md-center justify-content-lg-around justify-content-xxl-evenly align-items-center h-100"
                    >
                        <div className="row h-25 w-100">
                            <PlayerInfo
                                labelColor={'#41765c'}
                                color={opponentColor === 'w' ? 'white' : 'black'}
                                name={opponentInfo?.opponentName}
                                timePos="bottom"
                                resetTime={resetTime}
                                timerProps={botTimerProps}
                            />
                        </div>
                        <div
                            className="row w-100 h-50">
                            <div
                                className="control-room d-flex flex-column align-items-center justify-content-center">
                                <div className="room-duration">
                                    <Timer {...roomTimerProps} />
                                    <span>{languageJson.game.roomDuration}</span>
                                </div>
                                <div className="game-actions">
                                    <div className="item">
                                        <Button data-cy="game-new"
                                                onClick={() => {
                                                    setResetTime(false)
                                                    resetGame()
                                                    window.sendToGA('New Game Button Clicked')
                                                }}
                                                color={'#3A6D54'}
                                                disabled={!gameOver}
                                        >
                                            {languageJson.game.newGame}
                                        </Button>
                                    </div>
                                    <div className="item">
                                        <Button data-cy="game-resign"
                                                onClick={() => {
                                                    resignGame()
                                                    setResetTime(true)
                                                    window.sendToGA('Game Resign Button Clicked')
                                                }}
                                                color={'#FF6B39'}
                                                disabled={gameOver}
                                        >
                                            {languageJson.game.resignGame}
                                        </Button>
                                    </div>
                                    <div className="item">
                                        <Button data-cy="game-draw"
                                                onClick={() => {
                                                    gameState.drawGameButtonDisabled = true
                                                    drawGame()
                                                    setTimeout(() => {
                                                        setResetTime(true)
                                                    }, process.env.REACT_APP_DRAW_GAME_TIMEOUT || 2000)
                                                    window.sendToGA('Offer Draw Button Clicked')
                                                }}
                                                color={'#FFBA03'}
                                                disabled={drawGameButtonDisabled || gameOver}
                                        >
                                            {languageJson.game.drawGame}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row h-25 w-100">
                            <PlayerInfo
                                labelColor={'#ff6b39'}
                                color={opponentColor !== 'w' ? 'white' : 'black'}
                                name="You"
                                timePos="top"
                                resetTime={resetTime}
                                timerProps={userTimerProps}
                            />
                        </div>
                    </div>
                    <div
                        className="col col-sm-7 d-flex flex-column justify-content-md-between justify-content-xxl-evenly">
                        <div className="row w-100 h-25 d-flex align-items-baseline justify-content-center">
                            <div className="col col-lg-8">
                                <Cam speakerControlsOnly>
                                    {
                                        opponentInfo &&
                                        <video
                                            controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
                                            autoPlay={true}
                                            disablePictureInPicture={true}
                                            disableRemotePlayback={true}
                                            playsInline={true}
                                            src={loadWelcomeVideo ? opponentInfo.welcomeFile : opponentInfo.loopFile}
                                            preload="metadata"
                                            ref={videoEl}
                                            onEnded={nextVideo}
                                            muted={!opponentAudio}
                                        />
                                    }
                                </Cam>
                            </div>
                        </div>
                        <div className="row w-100 h-50">
                            <div className="flex-fill d-flex justify-content-center align-items-center position-static">
                                <ChessBoard/>
                            </div>
                        </div>

                        <div className="row w-100 h-25 d-flex align-items-end justify-content-center">
                            <div className="col col-lg-8">
                                <Cam showControls={true}>
                                    <Webcam
                                        audio={!audio}
                                        videoConstraints={video ? videoConstraints : false}
                                    />
                                </Cam>
                            </div>
                        </div>
                    </div>
                    <div className="col col-sm-3 d-flex flex-column align-items-center justify-content-center">
                        <div className="row w-100 h-25">
                            <CapturedPieces color={color}/>
                        </div>
                        <div className="row d-flex flex-column align-items-center justify-content-center w-100 h-100">
                            <div
                                className="row d-flex flex-column w-100 h-100 px-2 d-flex align-items-center justify-content-center">
                                <TurnTable/>
                            </div>
                        </div>
                        <div className="row w-100 h-25">
                            <CapturedPieces color={opponentColor}/>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    </div>)
}, () => false)
