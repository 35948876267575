import React, { useContext, useEffect, useState } from 'react'
import { Chess } from 'chess.js'
import { PIECES } from '../utils/constants'
import moment from 'moment'
import { LayoutContext } from '../store'
import { v4 as uuidv4 } from 'uuid'

const userId = uuidv4()

export const GameContext = React.createContext()

export const GameProvider = ({ children }) => {
    const { state: layoutState } = useContext(LayoutContext)

    const { opponentInfo, languageJson } = layoutState

    const [state, setState] = useState({
        opponentJoined: false,
        gameOver: false,
        drawGameButtonDisabled: false,
        myTurn: true,
        history: [],
        chat: [],
        capturedPieces: [],
        game: new Chess(),
        fen: null,
        color: 'w',
        promotionModalOpened: false,
        promotionPiece: null,
        move: '',
        check: false,
        score: {
            myWin: 0, myLoss: 0, myDraw: 0, oppWin: 0, oppLoss: 0, oppDraw: 0,
        },
        gamePrompt: {
            displayStatus: false,
            messageKey: ''
        }
    })

    useEffect(() => {
        if (opponentInfo && !state.opponentJoined) {
            setState((prev) => ({ ...prev, opponentJoined: true }))
        }
    }, [opponentInfo])

    // very start of game
    useEffect(() => {
        if (!state.opponentJoined) return

        let _history = [{
            labels: [{
                value: 'youJoinedRoom', notation: null,
            },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }, {
            labels: [{
                value: `${opponentInfo?.opponentName || languageJson.game.opponentName} joinedRoom`, notation: null,
            },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },]

        setState((prev) => ({
            ...prev, history: [...prev.history, ..._history],
        }))
    }, [state.opponentJoined])

    // check cases after move
    useEffect(() => {
        const { move, myTurn } = state

        if (!move) return

        let _state = {
            check: false,
        }
        let _history = []
        let _capturedPieces = []

        // checkPromotion

        // case start -----------------------------------

        //Move Notation

        _history.push({
            labels: [{
                value: `${PIECES[move.piece].toLowerCase()} ${move.from} moveNotation ${move.to}`,
                notation: {
                    color: move.color, san: move.san,
                },
            },],
        })

        //enPasant Case

        if (move.flags.includes('e')) {
            _history.push({
                labels: [{
                    value: 'enPassant', notation: null,
                },],
            })
        }

        //Capture Case
        if (move.san.includes('x')) {
            const capturedPiece = {
                san: move.san, piece: move.captured, color: move.color === 'w' ? 'b' : 'w',
            }

            _capturedPieces.push(capturedPiece)

            _history.push({
                labels: [{
                    value: `capture ${PIECES[move.captured].toLowerCase()}`, notation: null,
                },],
            })
        }

        //King Side Castle Case
        if (move && move.san === 'O-O') {
            _history.push({
                labels: [{
                    value: 'castlesKingSide', notation: null,
                },],
            })
        }

        //Queen Side Castle Case
        if (move && move.san === 'O-O-O') {
            _history.push({
                labels: [{
                    value: 'castlesQueenSide', notation: null,
                },],
            })
        }

        //Check Case
        if (move.san.includes('+')) {
            _state.check = true
            _history.push({
                labels: [{
                    value: 'check', notation: null,
                },],
            })
        }

        //Pawn Promotion Case
        if (move && move.san.includes('=')) {
            if (move.san.includes('Q')) {
                _history.push({
                    labels: [{
                        value: `promotesToQueen`, notation: null,
                    },],
                })
            } else if (move.san.includes('N')) {
                _history.push({
                    labels: [{
                        value: `promotesToKnight`, notation: null,
                    },],
                })
            } else if (move.san.includes('R')) {
                _history.push({
                    labels: [{
                        value: `promotesToRook`, notation: null,
                    },],
                })
            } else if (move.san.includes('B')) {
                _history.push({
                    labels: [{
                        value: `promotesToBishop`, notation: null,
                    },],
                })
            }
        }

        //Checkmate Case
        if (move.san.includes('#')) {
            _state.gameOver = true
            _history.push({
                labels: [{
                    value: 'checkmate', notation: null,
                },],
            })
            _history.push({
                labels: [{
                    value: !myTurn ? 'youLost' : 'youWin', notation: null,
                },],
            })

            changeScore('win', myTurn)
        }

        //Stalemate Case
        if (state.game.isStalemate()) {
            _state.gameOver = true
            _history.push({
                labels: [{
                    value: 'stalemate', notation: null,
                },],
            })
            _history.push({
                labels: [{
                    value: 'gameDrawn', notation: null,
                },],
            })
            changeScore('draw')
        }

        // Insufficient Material Case
        if (state.game.isInsufficientMaterial()) {
            _state.gameOver = true
            _history.push({
                labels: [{
                    value: 'insufficientMaterial', notation: null,
                },],
            })
            _history.push({
                labels: [{
                    value: 'gameDrawn', notation: null,
                },],
            })
            changeScore('draw')
        }

        // Threefold repetition Case
        if (state.game.isThreefoldRepetition()) {
            _state.gameOver = true
            _history.push({
                labels: [{
                    value: 'threeFoldRepetition', notation: null,
                },],
            })
            _history.push({
                labels: [{
                    value: 'gameDrawn', notation: null,
                },],
            })
            changeScore('draw')
        }

        //  case end ---------------------------

        setState((prev) => ({
            ...prev, ..._state,
            capturedPieces: [...prev.capturedPieces, ..._capturedPieces],
            history: [...prev.history, ..._history],
        }))
    }, [state.move])

    // turn label effect
    useEffect(() => {
        if (!state.opponentJoined) return
        if (state.game.isStalemate()) return
        if (state.game.isInsufficientMaterial()) return
        if (state.game.isThreefoldRepetition()) return

        let _history = [{
            labels: [{
                value: state.myTurn ? 'youTurn' : `${opponentInfo.opponentName}sTurn`, notation: null,
            },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },]

        // initiate bot move

        setState((prev) => ({
            ...prev, history: [...prev.history, ..._history],
        }))

        if (!state.myTurn) {
            setTimeout(makeRandomMove, 1000)
        }
    }, [state.myTurn, state.color, state.opponentJoined])

    const makeRandomMove = () => {
        const { game } = state

        let _state = {}
        let _history = []

        let allPossibleMoves = game.moves({ verbose: true })
        let possibleMoves = []

        if (opponentInfo.level === 'EASY') {
            possibleMoves = allPossibleMoves
        } else if (opponentInfo.level === 'HARD') {
            const possibleCaptureMoves = allPossibleMoves.filter(move => move.flags.includes('c'))
            const possibleOtherMoves = allPossibleMoves.filter(move => !move.flags.includes('c'))

            const queenCaptures = possibleCaptureMoves.filter(move => move.captured === 'q')
            const rookCaptures = possibleCaptureMoves.filter(move => move.captured === 'r')
            const knightCaptures = possibleCaptureMoves.filter(move => move.captured === 'n')
            const bishopCaptures = possibleCaptureMoves.filter(move => move.captured === 'b')
            const pawnCaptures = possibleCaptureMoves.filter(move => move.captured === 'p')

            possibleMoves = possibleOtherMoves

            if (possibleCaptureMoves.length) {
                if (queenCaptures.length) {
                    possibleMoves = queenCaptures
                } else if (rookCaptures.length) {
                    possibleMoves = rookCaptures
                } else if (knightCaptures.length) {
                    possibleMoves = knightCaptures
                } else if (bishopCaptures.length) {
                    possibleMoves = bishopCaptures
                } else if (pawnCaptures.length) {
                    possibleMoves = pawnCaptures
                }
            }
        }

        // game over
        if (possibleMoves.length === 0) {
            _state.gameOver = true
            return
        }

        const randomIdx = Math.floor(Math.random() * possibleMoves.length)
        game.move(possibleMoves[randomIdx])

        const move = game.history({ verbose: true })[game.history({ verbose: true }).length - 1]

        _state.fen = game.fen()
        _state.move = move

        if (!move.san.includes('#')) _state.myTurn = true

        setState((prev) => ({
            ...prev, ..._state, history: [...prev.history, ..._history],
        }))
    }

    const changeScore = (outcome, player) => {
        let { myWin, myLoss, myDraw, oppWin, oppLoss, oppDraw } = { ...state.score }
        const gamePrompt = { ...state.gamePrompt }

        gamePrompt.displayStatus = true

        switch (outcome) {
            case 'win':
                if (player) {
                    myWin++
                    oppLoss++
                    window.sendToGA('win', userId)
                    gamePrompt.messageKey = 'youWin'
                } else {
                    oppWin++
                    myLoss++
                    window.sendToGA('loss', userId)
                    gamePrompt.messageKey = 'youLost'
                }
                break
            case 'draw':
                myDraw += 0.5
                oppDraw += 0.5
                window.sendToGA('draw', userId)
                gamePrompt.messageKey = 'gameDrawn'
                break
            default:
                console.log('log game outcome', outcome)
        }

        setState((prev) => ({
            ...prev, score: { myWin, myLoss, myDraw, oppWin, oppLoss, oppDraw }, gamePrompt
        }))
    }

    const resetGame = () => {
        state.game.reset()
        setState((prev) => {
            const _color = prev.color === 'w' ? 'b' : 'w'
            const _myTurn = _color === 'w'
            return {
                ...prev,
                color: _color,
                move: '',
                gameOver: false,
                drawGameButtonDisabled: false,
                fen: state.game.fen(),
                myTurn: _myTurn,
                capturedPieces: [],
                history: [{
                    labels: [{
                        value: 'newGameStarted', notation: null,
                    },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },],
            }
        })
        setState((prev) => ({ ...prev, gamePrompt: { displayStatus: false, message: '' } }))
    }

    const resignGame = () => {
        let _history = []
        _history.push({
            labels: [{
                value: 'resigned', notation: null,
            },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })

        _history.push({
            labels: [{
                value: 'opponentWin', notation: null,
            },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })

        changeScore('win', !state.myTurn)

        setState((prev) => ({
            ...prev, history: [...prev.history, ..._history], gameOver: true,
        }))
    }

    const drawGame = () => {
        let _history = [{
            labels: [{
                value: 'youOfferedDraw', notation: null,
            },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },]

        setState((prev) => ({
            ...prev, history: [...prev.history, ..._history],
        }))

        // automate draw accept

        setTimeout(() => {
            let _history = [{
                labels: [{
                    value: 'opponentAcceptedDraw', notation: null,
                },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }, {
                labels: [{
                    value: 'gameDrawn', notation: null,
                },], date: moment().unix(), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },]

            setState((prev) => ({
                ...prev, history: [...prev.history, ..._history], gameOver: true,
            }))

            changeScore('draw')
        }, process.env.REACT_APP_DRAW_GAME_TIMEOUT || 2000)
    }

    return (
        <GameContext.Provider
            value={{
                state, setState, actions: {
                    drawGame, resignGame, resetGame, changeScore,
                },
            }}
        >
            {children}
        </GameContext.Provider>
    )
}
