import React, { useContext } from 'react'

import './navbar.scss'
import PalatarLogo from '../assets/ludo.webp'
import ExitIcon from '../assets/exit.webp'
import { LayoutContext } from '../store'

export const Navbar = () => {
    const { state: layoutState } = useContext(LayoutContext)
    const { languageJson } = layoutState
    return (
        <div className="navbar-container">
            <div className="content">
                <div className="nav-item logo">
                        <img data-cy="navbar-logo"
                             src={PalatarLogo}
                             alt="palatar-logo"
                             className="w-25 h-25"
                             onClick={() => {
                                 window.sendToGA('Palatar Logo Clicked from Top Right Corner')
                                 window.location.href = process.env.REACT_APP_REDIRECT_URL
                             }}/>
                </div>
                <div className="button-group">
                    <div className="join-btn">
                        <div
                            onClick={() => window.location.href = process.env.REACT_APP_JOIN_BUTTON_REDIRECT_URL}
                        >
                            {languageJson.game.joinNow}
                        </div>
                    </div>
                    <div data-cy="navbar-exit" className="nav-item navbar-action">
                        <img src={ExitIcon} alt="exit-icon" onClick={() => {
                            window.sendToGA('Exit Button Clicked from Top Right Corner')
                            window.location.href = process.env.REACT_APP_REDIRECT_URL
                        }} className={'w-50 h-50'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
